import { gql } from '@apollo/client/core';

export const USER_DATA_QUERY = gql`
  query {
    me {
      id
      username
      profile {
        firstName
        lastName
      }
      memberships {
        role
        business {
          id
          type
          name
          legalName
          tenant
          number
          logoUrl
          countryCode
          capabilities {
            restaurant
            serviceProvider
            supplier
            bookkeeping
          }
          contact {
            name
            phone
            address
            email
          }
        }
      }
    }
  }
`;

export const USER_PROFILE_QUERY = gql`
  query {
    me {
      id
      username
      profile {
        firstName
        lastName
        profilePicture
      }
    }
  }
`;
