<template>
  <PageLayout>
    <template #header>
      <h1 v-if="templateName">{{ $t('automation.automationSupplierManagement.template') }} {{ templateName }}</h1>
      <h1 v-else>{{ $t('automation.automationSupplierManagement.noTemplate') }}</h1>
    </template>
    <div class="d-flex justify-content-between mb-4">
      <h3>
        {{
          templateName
            ? $t('automation.automationSupplierManagement.documentsOfTemplate', { templateName })
            : $t('automation.automationManagement.general.documents')
        }}
      </h3>
      <Button class="py-1 px-2" @click="navigateToTemplate">
        <div class="d-flex align-items-center gap-1">
          <EditIcon />
          <p v-if="templateName">{{ $t('automation.automationSupplierManagement.editTemplate') }}</p>
          <p v-else>{{ $t('automation.automationSupplierManagement.createTemplate') }}</p>
        </div>
      </Button>
    </div>
    <Table
      v-loading="loading"
      :columns="columns"
      :show-index="(page - 1) * limit + 1"
      :data="tableData"
      :active-sort="activeSort"
      border
      rounded
      @row-click="navigateToTemplate"
    >
      <template #filter-issueDate>
        <h3 :class="$direction === 'rtl' ? 'ms-1' : 'me-1'">
          {{ $t('automation.automationSupplierManagement.filters.chooseTimeRange') }}
        </h3>
        <div class="text-typography-primary d-flex flex-column align-items-end p-0">
          <div class="block">
            <el-date-picker
              v-model="dateRange"
              format="dd MMMM yyyy"
              type="daterange"
              :start-placeholder="$t('automation.automationSupplierManagement.filters.startDate')"
              :end-placeholder="$t('automation.automationSupplierManagement.filters.endDate')"
            >
            </el-date-picker>
          </div>
        </div>
      </template>

      <template #filter-documentNumber>
        <div class="text-typography-primary d-flex flex-column align-items-end p-0">
          <el-input
            v-model="searchValue"
            clearable
            size="mini"
            class="mb-1"
            :placeholder="$t('automation.automationSupplierManagement.filters.documentSearch')"
          />
          <Button class="p-1 px-4" size="mini" @click="setNewDocumentNumber(searchValue)">
            {{ $t('commons.apply') }}
          </Button>
        </div>
      </template>

      <template #cell-documentNumber="{ rowData: { documentNumber } }">
        <p>{{ documentNumber }}</p>
      </template>

      <template #cell-numberOfItems="{ rowData: { items } }">
        <p>{{ items.length }}</p>
      </template>

      <template #cell-totalPriceIncludingTax="{ rowData: { totalPriceIncludingTax } }">
        <p>{{ totalPriceIncludingTax }}</p>
      </template>
    </Table>
    <div class="d-flex justify-content-end my-4">
      <el-pagination
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="page"
        :page-size="limit"
        :page-count="Math.ceil(totalCount / limit)"
        class-="float-right"
      />
    </div>
  </PageLayout>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { currencyInteger } from '@/locale/numberConfig';
import { DateTime } from 'luxon';

import { PageLayout, Table, Button } from '@/modules/core';
import { EditIcon } from '@/assets/icons';
import { options } from '@/locale/dateConfig';
import { useBusinesses } from '@/modules/auth';
import { useDocuments } from '@/modules/document/compositions';

import { useTemplates } from './compositions';

const PRODUCT_TABLE_HEADERS = {
  ISSUE_DATE: 'issueDate',
  SUPPLIER: 'supplier',
  RESTAURANT: 'restaurant',
  DOCUMENT_TYPE: 'documentType',
  DOCUMENT_NUMBER: 'documentNumber',
  NUMBER_OF_ITEMS: 'NumberOfItems',
  TOTAL_PRICE_INCLUDING_TAX: 'TotalPriceIncludingTax',
};

export default {
  components: { PageLayout, Table, Button, EditIcon },
  setup() {
    const root = getCurrentInstance().proxy;
    const limit = ref(20);
    const page = ref(1);
    const searchValue = ref('');
    const sortBy = ref('issueDate');
    const orderBy = ref('desc');
    const documentNumber = ref('');
    const yearAgo = new Date();
    yearAgo.setMonth(yearAgo.getMonth() - 12);
    const dateRange = ref([yearAgo, new Date()]);

    const { templates } = useTemplates(() => ({
      supplierId: root.$route.query.supplierId,
      documentType: root.$route.query.documentType,
      signedUrl: false,
    }));

    const template = computed(() =>
      templates.value.find(({ structureToken }) => structureToken === root.$route.query.structureToken)
    );

    const filterByDocumentNumber = (value) => {
      documentNumber.value = value;
      page.value = 1;
    };

    const { businesses, loading: businessesLoading } = useBusinesses();
    const variables = computed(() => ({
      types: [root.$route.query.documentType],
      supplierId: root.$route.query.supplierId,
      structureToken: root.$route.query.structureToken,
      pagination: {
        limit,
        offset: limit.value * (page.value - 1),
      },
      sort: {
        sortBy: sortBy.value,
        orderBy: orderBy.value,
      },
      fromDate: dateRange.value ? DateTime.fromJSDate(dateRange.value[0]).toISODate() : null,
      toDate: dateRange.value ? DateTime.fromJSDate(dateRange.value[1]).toISODate() : null,
      recorded: true,
    }));
    const { documents, totalCount, loading: documentsLoading } = useDocuments(variables);
    const navigateToTemplate = (index) => {
      root.$router.push({
        name: 'automationTemplateManagement',
        query: {
          supplierId: root.$route.query.supplierId,
          documentType: root.$route.query.documentType,
          documentId:
            typeof index === 'number' ? documents.value[index].id : template.value?.documentId ?? documents.value[0].id,
        },
      });
    };

    return {
      documents,
      page,
      dateRange,
      loading: computed(() => businessesLoading.value || documentsLoading.value),
      setNewDocumentNumber: filterByDocumentNumber,
      orderBy,
      searchValue,
      sortBy,
      limit,
      businesses,
      totalCount,
      navigateToTemplate,
      activeSort: {
        columnKey: PRODUCT_TABLE_HEADERS.ISSUE_DATE,
        direction: -1,
      },
      template,
      templateName: computed(() => template.value?.name),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('automation.automationSupplierManagement.headers.documentDate'),
          key: PRODUCT_TABLE_HEADERS.ISSUE_DATE,
          sortCallback: (direction) => {
            this.orderBy = direction === 1 ? 'asc' : 'desc';
            this.sortBy = 'issueDate';
          },
          filterActive: !!this.dateRange,
        },
        {
          header: this.$t('automation.automationSupplierManagement.headers.supplier'),
          key: PRODUCT_TABLE_HEADERS.SUPPLIER,
        },
        {
          header: this.$t('automation.automationSupplierManagement.headers.restaurant'),
          key: PRODUCT_TABLE_HEADERS.RESTAURANT,
        },
        {
          header: this.$t('automation.automationSupplierManagement.headers.documentType'),
          key: PRODUCT_TABLE_HEADERS.DOCUMENT_TYPE,
        },
        {
          header: this.$t('automation.automationSupplierManagement.headers.documentNumber'),
          key: PRODUCT_TABLE_HEADERS.DOCUMENT_NUMBER,
          filterActive: !!this.searchValue,
        },
        {
          header: this.$t('automation.automationSupplierManagement.headers.numberOfItems'),
          key: PRODUCT_TABLE_HEADERS.NUMBER_OF_ITEMS,
        },
        {
          header: this.$t('automation.automationSupplierManagement.headers.totalPriceIncludingTax'),
          key: PRODUCT_TABLE_HEADERS.TOTAL_PRICE_INCLUDING_TAX,
          sortCallback: (direction) => {
            this.orderBy = direction === 1 ? 'asc' : 'desc';
            this.sortBy = 'totalAmount';
          },
        },
      ];
    },
    tableData() {
      return this.documents.map((document) => {
        return {
          id: document.id,
          [PRODUCT_TABLE_HEADERS.ISSUE_DATE]: new Date(document.issueDate).toLocaleDateString(
            this.$i18n.locale,
            options.short
          ),
          [PRODUCT_TABLE_HEADERS.SUPPLIER]: document.supplier.name,
          [PRODUCT_TABLE_HEADERS.RESTAURANT]: this.businesses.find(({ id }) => id === document.businessId)?.name,
          [PRODUCT_TABLE_HEADERS.DOCUMENT_TYPE]: this.$t(`document.exports.schema.type.shortName.${document.type}`),
          [PRODUCT_TABLE_HEADERS.DOCUMENT_NUMBER]: document.documentNumber,
          [PRODUCT_TABLE_HEADERS.NUMBER_OF_ITEMS]: document.items?.length || 0,
          [PRODUCT_TABLE_HEADERS.TOTAL_PRICE_INCLUDING_TAX]: this.formatMoney(document.totalAmount) || '-',
        };
      });
    },
  },
  methods: {
    formatMoney(value) {
      return typeof value === 'number' ? Number(value).toLocaleString(this.$i18n.locale, currencyInteger) : '-';
    },
  },
};
</script>
