<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('automationManagement') }}</h1></template
    >
    <h3 class="mb-4">{{ $t('automation.automationManagement.templates') }} ({{ totalCount }})</h3>
    <Table
      v-loading="loading"
      :columns="columns"
      :data="tableData"
      show-index
      border
      rounded
      hover
      class="mb-4"
      @row-click="navigateToDocuments(tableData[$event])"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + PAGE_LIMIT * (currentPage - 1) }}
      </template>
      <template #cell-type="{ rowData: { type } }">
        <p>{{ $t(`document.exports.schema.type.shortName.${type}`) }}</p>
      </template>
      <template #cell-templateName="{ rowData: { templateName } }">
        <div style="display: flex; align-items: center">
          <div v-if="templateName && templateName.isArchived" class="icon">
            <el-tooltip :content="$t('automation.automationManagement.general.archived')" placement="top">
              <ArchiveIcon width="16"></ArchiveIcon>
            </el-tooltip>
          </div>

          <div v-if="templateName && templateName.isManual" class="icon">
            <PencilTrailIcon width="16"></PencilTrailIcon>
          </div>
          <TruncatedText style="width: 100%">
            <p>{{ templateName.name }}</p>
          </TruncatedText>
        </div>
      </template>

      <template #cell-logicalTemplate="{ rowData: { structureTemplate } }">
        <TruncatedText v-if="structureTemplate && structureTemplate.document">
          <Button type="link" class="p-0" @click.stop="selectedDocId = structureTemplate.document.id">
            {{ getDocTypeAndNum(structureTemplate) }}
          </Button>
        </TruncatedText>
        <span v-else>-</span>
      </template>
      <template #cell-graphicTemplate="{ rowData: { structureTemplate } }">
        <TruncatedText v-if="structureTemplate && structureTemplate.document && structureTemplate.fields.length">
          <Button type="link" class="p-0" @click.stop="selectedDocId = structureTemplate.document.id">
            {{ getDocTypeAndNum(structureTemplate) }}
          </Button>
        </TruncatedText>
        <span v-else>-</span>
      </template>
      <template #cell-percentOfAllDocuments="{ rowData: { percentOfAllDocuments } }">
        <p>{{ formatPercent(parseInt(percentOfAllDocuments)) }}</p>
      </template>
      <template #filter-supplier>
        <el-select
          v-model="supplierIdFilter"
          filterable
          clearable
          :popper-append-to-body="false"
          :placeholder="$t('commons.searchWithDots')"
        >
          <el-option v-for="supplier in suppliers" :key="supplier.id" :label="supplier.name" :value="supplier.id">
          </el-option>
        </el-select>
      </template>
    </Table>
    <div class="d-flex justify-content-end">
      <el-pagination
        hide-on-single-page
        class="px-0"
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="PAGE_LIMIT"
        :total="totalCount"
      />
    </div>
    <DocumentModal v-if="selectedDocId" visible :document-id="selectedDocId" @close="selectedDocId = null" />
  </PageLayout>
</template>

<script>
import { pick } from 'ramda';
import { ref, computed, watch, getCurrentInstance } from 'vue';
import { percent } from '@/locale/numberConfig';
import { options } from '@/locale/dateConfig';
import i18n from '@/imports/startup/client/i18n';
import { PencilTrailIcon, ArchiveIcon } from '@/assets/icons';

import { PageLayout, Table, Button, TruncatedText } from '@/modules/core';
import { DocumentModal } from '@/modules/documentModal';
import { useDocumentsAggregationNew3 } from '@/modules/document/compositions';

import { useStructureTemplate } from './compositions';
import { useSuppliersNew } from '../business/compositions';

const TEMPLATES_TABLE_HEADERS = {
  TEMPLATE_NAME: 'templateName',
  SUPPLIER: 'supplier',
  TYPE: 'type',
  LOGICAL_TEMPLATE: 'logicalTemplate',
  GRAPHIC_TEMPLATE: 'graphicTemplate',
  DOCUMENT_COUNT: 'documentCount',
  PERCENT_OF_ALL_DOCUMENTS: 'percentOfAllDocuments',
  UPDATED_AT: 'updatedAt',
  UPDATED_BY: 'updatedBy',
};

const PAGE_LIMIT = 20;

export default {
  components: { PageLayout, Table, Button, DocumentModal, TruncatedText, PencilTrailIcon, ArchiveIcon },
  setup() {
    const root = getCurrentInstance().proxy;
    const currentPage = ref(1);

    const { suppliers, loading: suppliersLoading } = useSuppliersNew();

    const yearAgo = new Date();
    yearAgo.setMonth(yearAgo.getMonth() - 12);

    // this is a date where the templates have change - and to fix it we limit the date
    const templatesChangeDate = new Date(2022, 6, 13);
    const startDate = new Date(Math.max(templatesChangeDate.getTime(), yearAgo.getTime()));
    const now = new Date();

    const supplierIdFilter = computed({
      get: () => root.$route.query.supplierId,
      set: (value) =>
        root.$router.replace({
          ...root.$route,
          query: { ...root.$route.query, supplierId: value || undefined },
        }),
    });

    watch([supplierIdFilter], () => (currentPage.value = 1), { immediate: true });

    const { structureTemplates, totalCount, loading } = useStructureTemplate(() => ({
      first: PAGE_LIMIT,
      after: ((currentPage.value - 1) * PAGE_LIMIT).toString(),
      fromDate: startDate,
      toDate: now,
      supplierId: supplierIdFilter.value,
    }));

    const { aggregation, loading: documentsAggregationLoading } = useDocumentsAggregationNew3({
      fromDate: startDate.toISOString().split('T')[0],
      toDate: now.toISOString().split('T')[0],
      recorded: true,
    });

    const navigateToDocuments = ({ structureTemplate }) => {
      root.$router.push({
        name: 'automationManagementDocuments',
        query: pick(['supplierId', 'documentType', 'structureToken'], structureTemplate),
      });
    };

    const getDocTypeAndNum = (template) =>
      `${root.$i18n.t(`document.exports.schema.type.shortName.${template.document.type}`)} ${
        template.document.documentNumber
      }`;

    const formatDate = (ms) => {
      return ms ? new Date(ms).toLocaleDateString(root.$i18n.locale, options.short) : null;
    };

    const tableData = computed(() => {
      return structureTemplates.value.map((structureTemplate) => {
        return {
          [TEMPLATES_TABLE_HEADERS.TEMPLATE_NAME]: structureTemplate.id
            ? {
                name: structureTemplate.name,
                isManual: structureTemplate.isManual,
                isArchived: structureTemplate.isArchived,
              }
            : { name: '-' },
          [TEMPLATES_TABLE_HEADERS.SUPPLIER]: structureTemplate.supplier.name,
          [TEMPLATES_TABLE_HEADERS.TYPE]: structureTemplate.documentType,
          [TEMPLATES_TABLE_HEADERS.DOCUMENT_COUNT]: structureTemplate.count,
          [TEMPLATES_TABLE_HEADERS.PERCENT_OF_ALL_DOCUMENTS]: (
            (structureTemplate.count * 100) /
            aggregation.value?.[0]?.count
          ).toFixed(2),
          [TEMPLATES_TABLE_HEADERS.UPDATED_AT]: structureTemplate?.updatedAt
            ? formatDate(structureTemplate.updatedAt)
            : '-',
          [TEMPLATES_TABLE_HEADERS.UPDATED_BY]: structureTemplate?.updatedBy ?? '-',
          structureTemplate,
        };
      });
    });

    const formatPercent = (value) => {
      return typeof value === 'number' && !Number.isNaN(value)
        ? Number(value / 100).toLocaleString(i18n.locale, percent)
        : '-';
    };

    return {
      navigateToDocuments,
      loading: computed(() => documentsAggregationLoading.value || loading.value || suppliersLoading.value),
      suppliers: computed(() => [...suppliers.value].sort((a, b) => a.name.localeCompare(b.name))),
      tableData,
      formatPercent,
      currentPage,
      PAGE_LIMIT,
      totalCount,
      selectedDocId: ref(null),
      getDocTypeAndNum,
      supplierIdFilter,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('automation.automationManagement.headers.template'),
          key: TEMPLATES_TABLE_HEADERS.TEMPLATE_NAME,
        },
        {
          header: this.$t('automation.automationManagement.headers.supplier'),
          key: TEMPLATES_TABLE_HEADERS.SUPPLIER,
          width: '180px',
          filterActive: !!this.supplierIdFilter,
        },
        {
          header: this.$t('automation.automationManagement.headers.documentType'),
          key: TEMPLATES_TABLE_HEADERS.TYPE,
        },
        {
          header: this.$t('automation.automationManagement.headers.logicalTemplateDefined'),
          key: TEMPLATES_TABLE_HEADERS.LOGICAL_TEMPLATE,
        },
        {
          header: this.$t('automation.automationManagement.headers.graphicTemplateDefined'),
          key: TEMPLATES_TABLE_HEADERS.GRAPHIC_TEMPLATE,
        },
        {
          header: this.$t('automation.automationManagement.headers.documentCount'),
          key: TEMPLATES_TABLE_HEADERS.DOCUMENT_COUNT,
          width: '145px',
        },
        {
          header: this.$t('automation.automationManagement.headers.percentOfAllDocuments'),
          key: TEMPLATES_TABLE_HEADERS.PERCENT_OF_ALL_DOCUMENTS,
          width: '140px',
        },
        {
          header: this.$t('automation.automationManagement.headers.updatedAt'),
          key: TEMPLATES_TABLE_HEADERS.UPDATED_AT,
        },
        {
          header: this.$t('automation.automationManagement.headers.updatedBy'),
          key: TEMPLATES_TABLE_HEADERS.UPDATED_BY,
        },
      ];
    },
  },
};
</script>

<style scoped>
.icon {
  margin-inline-end: 6px;
}
</style>
